<template>
  <div class="profile-authentication-area ptb-100">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="login-form">
            <b-alert variant="danger" :show="!!errorText">{{ errorText }}</b-alert>
            <b-alert :show="!!successText">{{ successText }}</b-alert>
            <form @submit.prevent.stop="onLogin">
              <div class="form-group">
                <label>Введите E-mail указанный при регистрации</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="login"
                  required
                  @input="errorText = ''"
                />
              </div>
              <button type="submit" :disabled="loading" style="position: relative">
                <div
                  v-if="loading"
                  class="spinner-border text-white"
                  style="
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: calc(50% - 10px);
                    top: calc(50% - 10px);
                  "
                />
                <span :class="{ invisible: loading }">
                  Отправить
                </span>
              </button>
              
              <!-- <br> -->
              <!-- <div class="text-center">
                Если Вы ещё не зарегистрированы, пройдите регистрацию
                <router-link
                  class="login-link default-btn mt-1"
                  to="/reg"
                  style="padding-left: 20px; padding-right: 20px;"
                >
                  Регистрация
                </router-link>
              </div> -->
              
              <div class="login-text">
                <StrapiBlocks :content="text" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useGlobalStore } from "@/store/global";
import { StrapiBlocks } from 'vue-strapi-blocks-renderer';

export default {
  name: "LoginRegisterContent",

  components: {
    StrapiBlocks
  },

  props: {
    text: Object,
  },

  data() {
    return {
      registerError: null,
      isRegisterLoading: false,
      hasActiveRef: false,
      globalStore: useGlobalStore(),

      login: '',
      password: '',

      successText: '',
      errorText: '',
      loading: false,
    };
  },

  methods: {
    async onLogin() {
      try {
        this.loading = true;

        axios
          .post("https://api.cadetdiktant.ru/auth/restore-password", {
            Login: this.login,
          })
          .then(() => {
            this.successText = `Новый пароль отправлен на вашу почту ${this.login}`
          })
          .catch(() => {
            this.errorText = `Такой Email ${this.login} у не зарегистрирован. Проверьте правильность написания почты или пройдите регистрацию. Или вы часто пытаетесь восстановить пароль. Попробуйте через 30 минут`
          })
          .finally(() => {
            this.login = '';
            this.loading = false;
          })
      } catch (error) {
        console.error(error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>

.profile-authentication-area {
  max-width: 800px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;

  .login-text {
    margin-top: 32px;

    & > * {
      color: #000;
    }
  }

  .forgot-password {
    text-align: right;
    margin-top: -16px;
    margin-bottom: 0;

    a {
      font-weight: 700;
      color: #2d9bf0;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
