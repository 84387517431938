<template>
  <div v-if="true">
    <NavbarStyleThree v-if="globalData" :global-data="globalData" />
    <div v-if="globalData">
      <PageTitle v-if="congratulations" :pageTitle="'Поздравляем! Вы ответили на все вопросы Всероссийского кадетского диктанта!'" />
      <PageTitle v-else :pageTitle="selectedTest ? selectedTest.Title  : 'Выбор диктанта'" />
      <div class="tests container position-relative" style="z-index: 1">
        <div v-if="congratulations">
          <div style="max-width: 600px; padding-top: 20px; padding-bottom: 20px; margin-left: auto; margin-right: auto;">
            <div
              v-if="globalData.dictant_congratulations"
              class="dictant-bottom"
            >
              <StrapiBlocks :content="globalData.dictant_congratulations" />
            </div>
          </div>
        </div>
        <div v-else-if="isReady">
          <div style="max-width: 600px; padding-top: 20px; padding-bottom: 20px; margin-left: auto; margin-right: auto;">
            <h6>Вы готовы? У вас есть 45 минут, чтобы ответить на 30 вопросов</h6>
            <div
              v-if="globalData.dictant_ready"
              class="dictant-bottom"
            >
              <StrapiBlocks :content="globalData.dictant_ready" />
            </div>
            <div class="text-center pb-4">
              <button
                class="default-btn"
                style="transform: none; margin-bottom: 0px"
                @click="startTest(isReady)"
              >
                <i class="flaticon-tick"></i>НАЧАТЬ<span></span>
              </button>
            </div>
          </div>
        </div>
        <div v-else-if="activeQuestion">
          <div class="questions-list">
            <div
              class="questions-list__item"
              v-for="(quest, index) in questions.length"
              :class="{
                'passed': index < activeQuestionIndex,
                'active': index === activeQuestionIndex
              }"
              :key="quest"
            >
              {{ quest }}
            </div>
            <div
              class="questions-list__timer"
              :class="{'questions-list__timer--almost-end': this.testLeftSeconds < 300000}"
            >
              {{leftTime}}
            </div>
          </div>
          <br />
          <div>
            <div style="max-width: 600px; margin-left: auto; margin-right: auto;">
              <div class="text-center">
                {{ activeQuestion.Description }}
                <br v-if="activeQuestion.Image" />
                <img
                  v-if="activeQuestion.Image"
                  :src="`https://images.cadetdiktant.ru/${activeQuestion.Image}`"
                  alt=""
                  :key="activeQuestion.Image"
                />
                <br v-if="activeQuestion.Image" />
              </div>
              <br />
              <component
                class="tests__answer"
                :class="{
                  'tests__answer--checked': answer.Id === checkedAnswer?.AnswerId || isChecked(answer),
                  'tests__answer--image': answer.Image
                }"
                v-for="answer in actualAnswers"
                :key="answer.Id"
                :is="'div'"
                @click="onCheckAnswer(answer)"
              >
                {{ answer.Description }}
                <br v-if="answer.Image" />
                <img
                  v-if="answer.Image"
                  :src="`https://images.cadetdiktant.ru/${answer.Image}`"
                  alt=""
                />
                <br v-if="answer.Image" />
                <br v-if="answer.Image" />
              </component>

              <br />
              <div class="text-center">
                <button
                  class="default-btn"
                  style="transform: none; margin-bottom: 0px"
                  :disabled="!checkedAnswer && checkedAnswers.length === 0"
                  @click="onSendAnswer"
                >
                  <i class="flaticon-tick"></i>Ответить<span></span>
                </button>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
        <div v-else-if="activeTests.length">
          <div
            v-for="test in activeTests"
            :key="test.Id"
            class="tests-item"
            @click="checkReady(test)"
          >
            {{ test.Title }}
          </div>
        </div>
        <div
          v-if="globalData.dictant_bottom && !activeQuestion && !isReady && !congratulations"
          class="dictant-bottom"
        >
          <StrapiBlocks :content="globalData.dictant_bottom" />
        </div>
      </div>
    </div>
    <Footer v-if="globalData" :global-data="globalData" />
  </div>
</template>

<script>
import { useGlobalStore } from "@/store/global.js";
import axios from "axios";
import PageTitle from "../Common/PageTitle";
import Footer from "../Layout/Footer";
import NavbarStyleThree from "../Layout/NavbarStyleThree.vue";
import { StrapiBlocks } from 'vue-strapi-blocks-renderer';

export default {
  name: "DictantPage",
  components: {
    NavbarStyleThree,
    PageTitle,
    Footer,
    StrapiBlocks
  },
  props: {
    globalData: Object,
  },
  data() {
    return {
      authToken: null,
      pageData: null,
      globalStore: useGlobalStore(),
      tests: [],
      selectedTest: null,
      selectedQuestion: null,
      questions: [],
      answers: [],
      activeQuestionIndex: null,
      testLeftSeconds: null,
      timer: null,
      checkedAnswer: null,
      isReady: false,
      lastFiveMinutes: false,
      checkedAnswers: [],
      congratulations: false
    };
  },
  computed: {
    activeTests() {
      let tests = []

      console.log(tests)

      this.tests.forEach(test => {
        if (test.Id === 11 || test.Id === 12) {
          if (test.Id === 11 && this.globalData.enable_dictant_27_11) {
            tests.push(test)
          }

          if (test.Id === 12 && this.globalData.enable_dictant_28_11) {
            tests.push(test)
          }
          
          return
        }
        
        tests.push(test);
      });

      return tests
    },
    leftTime() {
      const totalSeconds = Math.floor(this.testLeftSeconds / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;

      if (hours > 0) {
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")}`;
      } else {
        return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
          2,
          "0"
        )}`;
      }
    },
    activeQuestion() {
      return this.questions.find(
        (_, index) => index === this.activeQuestionIndex
      );
    },
    isFewAnswersMaybe() {
      return this.activeQuestion.Count > 1
    },
    actualAnswers() {
      return this.answers.filter(
        (answer) => answer.PassingQuestionId === this.activeQuestion?.Id
      );
    },
  },
  methods: {
    isChecked(answer) {
      if (!this.isFewAnswersMaybe) {
        return false
      }

      const findAnswerIndex = this.checkedAnswers.findIndex(item => item.AnswerId === answer.Id)

      return findAnswerIndex >= 0
    },
    updateTime() {
      if (this.testLeftSeconds > 0) {
        this.testLeftSeconds -= 1000;
      } else {
        clearInterval(this.timer);
      }
    },
    async onCheckAnswer(answer) {
      if (this.isFewAnswersMaybe) {
        const findAnswerIndex = this.checkedAnswers.findIndex(item => item.AnswerId === answer.Id)

        if (findAnswerIndex >= 0) {
          this.checkedAnswers.splice(findAnswerIndex, 1)

          return
        } else {
          this.checkedAnswers.push({
            Id: this.selectedTest.Id,
            QuestionId: answer.PassingQuestionId,
            AnswerId: answer.Id,
          })
        }
      }

      this.checkedAnswer = {
        Id: this.selectedTest.Id,
        QuestionId: answer.PassingQuestionId,
        AnswerId: answer.Id,
      };
    },
    async onSendAnswer() {
      let answerToSend = this.checkedAnswer;

      if (this.isFewAnswersMaybe) {
        answerToSend = {
          Id: answerToSend.Id,
          QuestionId: answerToSend.QuestionId,
          answerIds: this.checkedAnswers.map(item => item.AnswerId)
        }
      }

      try {
        await axios
          .post(
            "https://api.cadetdiktant.ru/passing/answer",
            answerToSend,
            {
              headers: {
                Authorization: `Bearer ${this.globalStore.jwt}`,
              },
            }
          )
          .then((response) => {
            this.checkedAnswer = null;
            this.checkedAnswers = []

            if (response.data.Result) {
              this.activeQuestionIndex += 1;
            } else {
              this.congratulations = true;
              this.activeQuestionIndex = null;
              this.selectedTest = null;
              this.questions = [];
            }
          });
      } catch (error) {
        console.error(error);
      }
    },
    getSymbol(index) {
      if (index === 0) {
        return "A";
      }

      if (index === 1) {
        return "Б";
      }

      if (index === 2) {
        return "В";
      }

      if (index === 3) {
        return "Г";
      }
    },
    checkReady(test) {
      this.isReady = test;
      this.selectedTest = test
    },
    startTest(test) {
      this.selectedTest = test;
      const Id = this.selectedTest.Id;

      try {
        axios
          .post(
            "https://api.cadetdiktant.ru/passing/start",
            { Id },
            {
              headers: {
                Authorization: `Bearer ${this.globalStore.jwt}`,
              },
            }
          )
          .then(async (response) => {
            if (response.data.Seconds === 0) {
              alert("Время для прохождения данного теста истекло");
              return;
            }

            this.testLeftSeconds = response.data.Seconds * 1000;
            this.timer = setInterval(this.updateTime, 1000);

            await axios
              .post(
                "https://api.cadetdiktant.ru/passing/answers",
                { Id },
                {
                  headers: {
                    Authorization: `Bearer ${this.globalStore.jwt}`,
                  },
                }
              )
              .then((res) => {
                this.answers = res.data?.Data;

                if (this.activeQuestionIndex === null) {
                  this.activeQuestionIndex = 0;
                } else {
                  this.activeQuestionIndex += 1;
                }
              });

            await axios
              .post(
                "https://api.cadetdiktant.ru/passing/questions",
                { Id },
                {
                  headers: {
                    Authorization: `Bearer ${this.globalStore.jwt}`,
                  },
                }
              )
              .then((res) => {
                this.questions = res.data?.Data;
                this.isReady = false;
              });
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
  async mounted() {
    if (!this.globalStore.jwt) {
      this.$router.push('/login')
      return;
    }

    try {
      axios
        .post(
          "https://api.cadetdiktant.ru/passing/list",
          {},
          {
            headers: {
              Authorization: `Bearer ${this.globalStore.jwt}`,
            },
          }
        )
        .then((res) => {
          this.tests = res.data?.Data;
        });
    } catch (error) {
      this.globalStore.logout();
      localStorage.removeItem('user_jwt');
      localStorage.removeItem('user_name');
      this.$router.push('/login')
      console.error(error);
    }
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  watch: {
    testLeftSeconds(val) {
      if (val <= 0) {
        alert("Время закончилось!!!");
        this.checkedAnswer = null;
        this.activeQuestionIndex = null;
        this.selectedTest = null;
        this.questions = [];
      }
    },
  },
};
</script>

<style lang="scss">
.navbar-area.navbar-style-two.is-sticky + div {
  padding-top: 57px;

  @media (min-width: 1200px) {
    padding-top: 85.5px;
  }
}

body .page-title-area {
  padding: 60px 0 0;
}

.tests {
  padding-top: 24px;

  img {
    border: 1px solid grey;
  }

  &__answer {
    margin-bottom: 12px;
    display: block;
    cursor: pointer;
    background: #7fdcfb;
    border-radius: 6px;
    text-align: center;
    padding: 8px;
    font-size: 20px;

    img {
      width: 100%;
      max-width: none;
      border-radius: 6px;
      // width: 120px;
      // border-width: 1px;
    }

    &--image {
      padding: 0;
      font-size: 0;
      margin-bottom: 24px;
    }

    &--checked {
      background: green;
      color: #fff;

      img {
        box-shadow: 0 0 0 10px green;
      }
    }
  }

  strong.tests__answer img {
    border-width: 5px;
  }
}

.default-btn[disabled="true"],
.default-btn.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.dictant-bottom {
  padding: 20px 0 40px;

  p {
    color: var(--bs-body-color);
  }
}

.tests-item {
  margin-bottom: 20px;
  padding: 16px;
  border-radius: 16px;
  background: #ff5d22;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    background: #221638;
  }
}

.questions-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &__item {
    width: 32px;
    margin: 3px;
    height: 32px;
    background: #ff5d22;
    text-align: center;
    color: #fff;
    line-height: 34px;
    font-weight: 600;
    border-radius: 4px;

    &.passed {
      background: green;
    }

    &.active {
      background: yellow;
      color: #221638;
    }
  }

  &__timer {
    color: #ff5d22;
    font-weight: 700;
    font-size: 36px;
    margin-left: 3px;

    &--almost-end {
      color: red;
    }
  }
}
</style>
