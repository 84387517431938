<template>
  <div class="partner-area pt-100 pb-70 bg-f1f8fb">
    <div class="container">
      <div class="section-title">
        <h2>Партнеры</h2>
      </div>
      <div class="row align-items-center">
        <div
          class="col-lg-2 col-6 col-sm-4 col-md-4"
          v-for="item in data.data"
          :key="item.id"
        >
          <div class="single-partner-item">
            <img
              :src="`${process.VUE_APP_STRAPI_URL}${item.attributes.url}`"
              alt="image"
            />
            <div class="single-partner-item__title">
              {{ item.attributes.caption }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="divider"></div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Partner",
  props: {
    data: Object,
  },
  data: () => ({
    process: process.env,
  }),
};
</script>

<style lang="scss" scoped>
.single-partner-item__title {
  margin-top: 12px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  min-height: 18px;
}
</style>