<template>
  <div v-if="globalData">
    <NavbarStyleThree v-if="globalData" :global-data="globalData" />
    <div>
      <PageTitle pageTitle="Диктант завершился. Благодарим за участие!" />
      <br>
      <br>
      <br>
      <!-- <PageTitle pageTitle="Вход/Регистрация" />
      <LoginContent :text="globalData.login_text" /> -->
    </div>
    <Footer v-if="globalData" :global-data="globalData" />
  </div>
</template>

<script>
import { useGlobalStore } from '@/store/global.js'
import axios from "axios";
import PageTitle from "../Common/PageTitle";
// import LoginContent from "../LoginRegister/LoginContent";
import Footer from "../Layout/Footer";
import NavbarStyleThree from "../Layout/NavbarStyleThree.vue";

export default {
  name: "LoginPage",
  components: {
    NavbarStyleThree,
    PageTitle,
    // LoginContent,
    Footer,
  },
  props: {
    globalData: Object,
  },
  data() {
    return {
      pageData: null,
      registerError: null,
      registerSuccess: null,
      globalStore: useGlobalStore()
    };
  },
  async mounted() {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_STRAPI_URL}/api/main-page?populate=deep`,
        {
          headers: {
            Authorization: `Bearer ${process.env.VUE_APP_STRAPI_TOKEN}`,
          },
        }
      );

      if (response?.data?.data?.attributes) {
        this.pageData = response.data.data.attributes;
      }
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style lang="scss">
.navbar-area.navbar-style-two.is-sticky+div {
  padding-top: 57px;

  @media (min-width: 1200px) {
    padding-top: 85.5px;
  }
}

body .page-title-area {
  padding: 60px 0 0;
}
</style>