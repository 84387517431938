<template>
  <div class="profile-authentication-area ptb-100">
    <div class="container">
      <div class="row">
        <div v-if="isTeacher" class="col-lg-12 col-md-12">
          <div class="login-form">
            <b-alert :show="!!registerSuccess">{{ registerSuccess }}</b-alert>
            <form @submit.prevent.stop="onRegisterTeacher">
              <div class="form-group">
                <label>Актуальная информация</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="register.Information"
                  required
                />
              </div>

              <div class="form-group">
                <label>Область/Регион</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="register.Region"
                  required
                />
              </div>

              <div class="form-group">
                <label>Город</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="register.City"
                  required
                />
              </div>

              <div class="form-group">
                <label>Школа (учебное заведение)</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="register.School"
                  required
                />
              </div>

              <div class="form-group">
                <label>Должность</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="register.Position"
                />
              </div>

              <div class="form-group">
                <label>Фамилия</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="register.Lastname"
                  required
                />
              </div>

              <div class="form-group">
                <label>Имя</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="register.Firstname"
                  required
                />
              </div>

              <div class="form-group">
                <label>Отчество</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="register.Middlename"
                />
              </div>

              <div class="form-group">
                <label>E-mail</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="register.Email"
                  required
                />
              </div>

              <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-sm-12 remember-me-wrap">
                  <p>
                    <input
                      type="checkbox"
                      id="test2"
                      v-model="register.Agreement"
                      required
                    />
                    <label for="test2"
                      >Я ознакомлен с положением и даю согласие на обработку
                      персональных данных!</label
                    >
                  </p>
                </div>

                <!-- <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                  <a href="#" class="lost-your-password">Lost your password?</a>
                </div> -->
              </div>

              <button type="submit" style="position: relative">
                <div
                  v-if="isRegisterLoading"
                  class="spinner-border text-white"
                  style="
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: calc(50% - 10px);
                    top: calc(50% - 10px);
                  "
                />
                <span :class="{ invisible: isRegisterLoading }">
                  Регистрация
                </span>
              </button>
            </form>
          </div>
        </div>

        <div v-else class="col-lg-12 col-md-12">
          <StrapiBlocks
            v-if="topText"
            :content="topText"
          />
          <div class="login-form">
            <b-alert :show="!!registerSuccess">{{ registerSuccess }}</b-alert>
            <b-alert variant="danger" :show="!!registerError">{{ registerError }}</b-alert>
            <!-- <form @submit.prevent.stop="showRegisterModal = true"> -->
            <form @submit.prevent.stop="onRegisterStudent">
              <div class="form-group">
                <label>Область/Регион</label>
                <select
                  type="text"
                  class="form-select"
                  v-model="registerStudent.Region"
                  @change="onRegionSelect"
                >
                  <option
                    v-for="item in regions"
                    :value="item.Name"
                    :key="item.Id"
                  >
                    {{ item.Name }}
                  </option>
                </select>
                <input
                  v-if="registerStudent.Region === '--- Свой вариант ---'"
                  type="text"
                  class="form-control mt-2"
                  placeholder="Свой вариант"
                  required
                  v-model="regionCustom"
                />
              </div>

              <div class="form-group">
                <label>Город</label>
                <select
                  v-if="registerStudent.Region !== 'Свой вариант'"
                  type="text"
                  class="form-select"
                  v-model="registerStudent.City"
                >
                  <option
                    v-for="item in cities"
                    :value="item.Name"
                    :key="item.Id"
                  >
                    {{ item.Name }}
                  </option>
                </select>

                <input
                  v-if="registerStudent.Region === '--- Свой вариант ---' || registerStudent.City === '--- Свой вариант ---'"
                  type="text"
                  class="form-control mt-2"
                  placeholder="Свой вариант"
                  v-model="cityCustom"
                  required
                  :readonly="hasActiveRef"
                />
              </div>

              <div class="form-group">
                <label>Школа (учебное заведение)</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="registerStudent.School"
                  :readonly="hasActiveRef"
                />
              </div>

              <div class="form-group">
                <label>Класс</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="registerStudent.Position"
                />
              </div>

              <div class="form-group">
                <label>Фамилия</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="registerStudent.Lastname"
                  required
                />
              </div>

              <div class="form-group">
                <label>Имя</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="registerStudent.Firstname"
                  required
                />
              </div>

              <div class="form-group">
                <label>Отчество</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="registerStudent.Middlename"
                />
              </div>

              <div class="form-group">
                <label>E-mail</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="registerStudent.Email"
                  required
                  @input="registerError = null"
                />
              </div>

              <div class="row align-items-center">
                <div class="col-lg-12 col-md-12 col-sm-12 remember-me-wrap">
                  <p>
                    <input
                      type="checkbox"
                      id="test3"
                      v-model="registerStudent.Agreement"
                      required
                    />
                    <label for="test3">
                      Надпись "Я ознакомлен с <a href="/polozhenie" target="_blank">Положением</a> и даю <a href="/soglasiye" target="_blank">Согласие</a> на обработку персональных данных!
                    </label>
                  </p>
                </div>
              </div>

              <button type="submit" :disabled="isRegisterLoading" style="position: relative">
                <div
                  v-if="isRegisterLoading"
                  class="spinner-border text-white"
                  style="
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    left: calc(50% - 10px);
                    top: calc(50% - 10px);
                  "
                />
                <span :class="{ invisible: isRegisterLoading }">
                  Регистрация
                </span>
              </button>
            </form>
          </div>
        </div>

        <div v-if="showRegisterModal" class="register-modal">
          <div class="register-modal__inner">
            <h4>Проверка данных регистрации</h4>
            <div class="register-modal__subtitle">Данные верны?</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { StrapiBlocks } from 'vue-strapi-blocks-renderer';

export default {
  name: "LoginRegisterContent",

  props: {
    isTeacher: Boolean,
    topText: Object
  },

  components: {
    StrapiBlocks
  },

  data() {
    return {
      registerError: null,
      isRegisterLoading: false,
      hasActiveRef: false,

      registerInitial: {
        Information: "",
        Region: "",
        City: "",
        School: "",
        Position: "",
        Lastname: "",
        Firstname: "",
        Middlename: "",
        Email: "",
        Agreement: "",
      },
      register: {
        Information: "",
        Region: "",
        City: "",
        School: "",
        Position: "",
        Lastname: "",
        Firstname: "",
        Middlename: "",
        Email: "",
        Agreement: "",
      },

      registerStudentInitial: {
        // Ref: "",
        Region: "",
        City: "",
        School: "",
        Position: "",
        // Information: "",
        Lastname: "",
        Firstname: "",
        Middlename: "",
        Email: "",
        Agreement: "",
      },
      registerStudent: {
        Region: "",
        City: "",
        School: "",
        Position: "",
        Lastname: "",
        Firstname: "",
        Middlename: "",
        Email: "",
        Agreement: "",
      },

      regions: [],
      cities: [],
      showRegisterModal: false,
      regionCustom: '',
      cityCustom: ''
    };
  },

  computed: {
    registerFormatted() {
      return {
        ...this.register,
        agreement: this.register.agreement ? "on" : this.register.agreement,
      };
    },
    registerStudentFormatted() {
      const studentData = this.registerStudent;

      if (this.registerStudent.Region === '--- Свой вариант ---') {
        studentData.Region1 = this.regionCustom;
      }

      return {
        ...studentData,
        agreement: this.registerStudent.agreement
          ? "on"
          : this.registerStudent.agreement,
      };
    },
  },

  methods: {
    async onRegionSelect() {
      const regionId = this.regions.find(item => item.Name === this.registerStudent.Region)?.Id;

      if (!regionId || regionId === null) {
        return
      }

      await axios.post('https://api.cadetdiktant.ru/auth/cities', {
        RegionId: regionId
      })
        .then(response => {
          this.cities = response.data.List.sort((a, b) => {
            const nameA = a.Name.toLowerCase();
            const nameB = b.Name.toLowerCase();
            
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          })

          this.cities.push({
            Id: null,
            Code: null,
            Name: '--- Свой вариант ---'
          })
        })
    },
    async onRegisterTeacher() {
      this.isRegisterLoading = true;
      try {
        await axios
          .post(
            `https://api.cadetdiktant.ru/auth/register-teacher`,
            this.registerFormatted,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(async () => {
            this.registerError = null;
            this.registerSuccess = "Письмо отправлено на Ваш E-mail";
            this.register = this.registerInitial;

            const loginForm = document.querySelector(".login-form");
            const rect = loginForm.getBoundingClientRect();
            const offset = window.scrollY + rect.top - 100; // 100 пикселей выше
            window.scrollTo({ top: offset, behavior: "smooth" });
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.isRegisterLoading = false;
      }
    },
    async onRegisterStudent() {
      this.isRegisterLoading = true;

      const data = JSON.parse(JSON.stringify(this.registerStudentFormatted))

      if (data.Region1) {
        data.Region = data.Region1;
        delete data.Region1
      }

      if (this.cityCustom) {
        data.City = this.cityCustom;
      }

      try {
        await axios
          .post(
            `https://api.cadetdiktant.ru/auth/register`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (resp) => {
            this.registerSuccess = null;

            if (resp.data === false) {
              this.registerError = 'Данный e-mail уже зарегистрирован. Проверьте вашу почту или зарегистрируйте другой'  
              const loginForm = document.querySelector(".login-form");
              const rect = loginForm.getBoundingClientRect();
              const offset = window.scrollY + rect.top - 100; // 100 пикселей выше
              window.scrollTo({ top: offset, behavior: "smooth" });
              return
            }

            this.registerError = null;
            this.registerSuccess = `Поздравляем, вы зарегистрированы! Ваши данные для входа отправлены на указанный E-mail ${data.Email}`;
            this.registerStudent = this.registerStudentInitial;

            const loginForm = document.querySelector(".login-form");
            const rect = loginForm.getBoundingClientRect();
            const offset = window.scrollY + rect.top - 100; // 100 пикселей выше
            window.scrollTo({ top: offset, behavior: "smooth" });
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.isRegisterLoading = false;
      }
    },
  },

  async mounted() {
    const ref = this.$route.query.ref;

    try {
      await axios.post(`https://api.cadetdiktant.ru/auth/regions`)
        .then((response) => {
          this.regions = response.data.List.sort((a, b) => {
            const nameA = a.Name.toLowerCase();
            const nameB = b.Name.toLowerCase();
            
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            return 0;
          })

          this.regions.push({
            Id: null,
            Code: null,
            Name: '--- Свой вариант ---'
          })
        })
    } catch (error) {
      console.error(error)
    }

    if (!this.isTeacher && ref) {
      this.hasActiveRef = false;

      this.registerStudent.Ref = ref;

      try {
        await axios
          .get(`https://api.cadetdiktant.ru/auth/referal?ref=${ref}`, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(async (response) => {
            this.registerStudent.Region = response.data.Region;
            this.registerStudentInitial.Region = response.data.Region;

            this.registerStudent.City = response.data.City;
            this.registerStudentInitial.City = response.data.City;

            this.registerStudent.School = response.data.School;
            this.registerStudentInitial.School = response.data.School;

            if (this.registerStudent.Region) {
              this.hasActiveRef = true;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } catch (error) {
        console.error(error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.register-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .6);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  &__inner {
    background: #fff;
    border-radius: 16px;
    padding: 24px;
    max-width: 85vw;
    width: 960px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 85vh;
    overflow: auto;
  }

  &__subtitle {
    color: red;
    font-weight: bold;
    margin: 20px 0;
  }
}

.profile-authentication-area {
  max-width: 800px;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
}
</style>
