<template>
  <div class="subscribe-area ptb-100">
    <div class="container">
      <div class="row align-items-center">
        <div v-if="data.image.data" class="col-lg-6 col-md-12">
          <div class="subscribe-image">
            <img :src="`${process.VUE_APP_STRAPI_URL}${data.image.data.attributes.url}`" data-speed="0.06"
              data-revert="true" alt="image" />
          </div>
        </div>

        <div class="col-md-12" :class="{ 'col-lg-6': data.image.data }">
          <div class="subscribe-content">
            <h2 v-if="data.title">{{ data.title }}</h2>
            <p v-if="data.text">
              {{ data.text }}
            </p>
            <!-- <form class="newsletter-form"> -->
            <!-- <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required> -->
            <div class="dropdown" v-if="data.ButtonText">
              <button type="submit" class="default-btn" style="transform: none; margin-bottom: 0;"
                data-bs-toggle="dropdown" aria-expanded="false">
                <i class="flaticon-tick"></i>{{ data.ButtonText }}<span></span>
              </button>
              <ul class="dropdown-menu">
                <li><router-link to="/register" class="dropdown-item" href="#">Ученик</router-link></li>
                <li><router-link to="/register-teacher" class="dropdown-item" href="#">Преподователь</router-link></li>
              </ul>
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>

    <div class="vector-shape6">
      <img
        v-if="data.bg?.data"
        :src="`${process.VUE_APP_STRAPI_URL}${data.bg?.data?.attributes.url}`"
        alt="image"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "WeLikeToStartTwo",
  props: {
    data: Object,
  },
  data: () => ({
    process: process.env,
  }),
};
</script>