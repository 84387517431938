import { defineStore } from 'pinia'

export const useGlobalStore = defineStore('global', {
  state: () => ({
    jwt: '',
    userName: ''
  }),
  actions: {
    setJwt(value) {
      this.jwt = value
    },
    setUserName(value) {
      this.userName = value
    },
    logout() {
      this.jwt = '';
      this.userName = '';
    }
  }
})